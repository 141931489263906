
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div id=\"services-search\">\n	<div class=\"container services-search-row\">\n		<div class=\"services-search-row-main\">\n			<form role=\"form\" id=\"iss-search_form\">\n				<svg id=\"search-icon\" height=\"30px\" width=\"30px\" class=\"search-icon\" viewBox=\"0 0 24 24\">\n					<path d=\"M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z\"></path>\n					<path d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n				</svg>\n				<div class=\"iss-keyword-search\">\n					<label class=\"sr-only\" for=\"iss-keyword-search_input\">Services or keywords</label>\n\n					<input type=\"text\" class=\"search-services-box form-control\" id=\"iss-keyword-search_input\" placeholder=\"Services or keywords\" size=\"40\">\n				</div>\n				<div class=\"iss-location-search\">\n					<label class=\"sr-only\" for=\"iss-location-search_input\">Suburb or postcode in NSW</label>\n					<input type=\"text\" class=\"search-locations-box form-control\" id=\"iss-location-search_input\" placeholder=\"Suburb or postcode in NSW\" size=\"60\">\n				</div>\n				<button type=\"submit\" id=\"iss-search_submit\" class=\"btn btn-primary with-icon\">Find services</button>\n					"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "spinner.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n			</form>\n			<div class=\"help-block\">\n				<a href=\"https://www.serviceseeker.com.au/\" target=\"_blank\">\n					Powered by Infoxchange Service Seeker</a>\n			</div>\n		</div><!-- services-search-row-main -->\n\n		<div class=\"services-search-row-auxiliary\">\n			<button class=\"btn btn-primary-dark btn-auxiliary advanced_search\">\n				<div class=\"inner\">\n					<i class=\"fa fa-bullseye\"></i>\n					<div class=\"text\">Advanced Search</div>\n				</div>\n			</button>\n			<button class=\"btn btn-primary-dark btn-auxiliary add-service\">\n				<div class=\"inner\">\n					<i class=\"fa fa-plus\"></i>\n					<div class=\"text\">Add Service</div>\n				</div>\n			</button>\n		</div><!-- services-search-row-auxiliary -->\n	</div>\n	<div class=\"row\">\n		<div id=\"search-sidebar\">\n		</div>\n		<div id=\"leaflet-map\" class=\"col-lg-2\"></div>\n	</div>\n</div>\n";
},"useData":true});
              Handlebars.templates['src/hbs/main.hbs'] = templateFunction;
              export default templateFunction;
              