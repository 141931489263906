
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  "
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "entity_list_controls.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.is_from_service_types_list : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<div id=\"totalRecords_container\"></div>\n\n<div id=\"search-results\"></div>\n\n<div id=\"pagination\">\n	<ul></ul>\n</div>\n";
},"useData":true});
              Handlebars.templates['src/hbs/entity_list.hbs'] = templateFunction;
              export default templateFunction;
              